<template>
  <!-- Container fluid  -->
  <div class="container-fluid">
      <!-- Start Page Content -->
      <div class="row">
          <div class="col-12">
              <div class="card">
                  <div id="report" class="card-body">
                      <div class="container-fluid">
                        <div style="width: 80px;">
                          <img v-bind:src="$store.state.logo" style="max-width:100%; max-height:100%; display:block;"/>
                        </div>
                        <div class="headerreport">
                          <div class="row">
                            {{ companydata.cmp_nmbre }}
                          </div>
                          <div class="row">
                            {{ fecha }}
                          </div>
                        </div>
                      </div>
                      <br>
                      <div class="card-title">
                        <h1 class="titlereport">Reporte de Correspondencia radicada</h1>
                        <h5 class="subtitle">Clase de documentos: {{ tcr_nmbre }}</h5>
                        <h5 class="subtitle">{{ rango }}</h5>
                        <h5 class="subtitle" v-if="nuevos">Sólo expedientes nuevos</h5>
                      </div>
                      <div id="wrapping" class="table-responsive m-t-40 bodyreport">
                          <table id="reporteDocsRadicados" class="table table-sm display nowrap table-striped table-bordered" cellspacing="0" width="100%">
                              <thead>
                                  <tr>
                                      <th>Año</th>
                                      <th>Consecutivo</th>
                                      <th>Fecha Radicación</th>
                                      <th>Tipo solicitud</th>
                                      <th class="entidad">Entidad</th>
                                      <th class="asunto">Asunto</th>
                                      <th>Ciudad</th>
                                      <th class="dependencia">Dependencia</th>
                                      <th class="serie">Serie</th>
                                      <th>Índices</th>
                                      <th>Pendiente respuesta</th>
                                      <th>Fecha Último Seguimiento</th>
                                      <th>Para Usuario U. Seg.</th>
                                      <th>Estado U. Seg.</th>
                                      <th>Respuesta</th>
                                      <th>Nuevo expediente</th>
                                      <th>Datos de Pqrs</th>
                                      <th>Id Email</th>
                                      <th>Fecha Email</th>
                                      <!--
                                      <th>Tiempo respuesta</th>
                                      <th>Tiempo restante</th>
                                    -->
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="(item, index) in items" class="rowstyle">
                                    <td>{{ item.dcm_ano }}</td>
                                    <td>{{ item.dcm_cdgo }}</td>
                                    <td>{{ item.dcm_fcharad | formatDate }}</td>
                                    <td>{{ item.tpq_nmbre }}</td>
                                    <td>{{ item.dcm_entidad }}</td>
                                    <td>{{ item.dcm_asunto }}</td>
                                    <td>{{ item.dcm_ciudad }}</td>
                                    <td>{{ item.dep_nmbre }}</td>
                                    <td>{{ item.ser_nmbre }}</td>
                                    <td>
                                      <table class="table table-sm">
                                        <tr v-for="i in item.indices">
                                          <td>{{ i.ind_nmbre }}</td>
                                          <td>{{ i.ixd_valor }}</td>
                                        </tr>
                                      </table>
                                    </td>
                                    <td>{{ item.dcm_rspsta | formatBoolean }}</td>
                                    <td>{{ item.FechaSeguimiento | formatDate }}</td>
                                    <td>{{ item.ParaUsuarioNombre }}</td>
                                    <td>{{ item.EstadoNombre }}</td>
                                    <td>{{ item.respuesta }}</td>
                                    <td>{{ item.nuevo }}</td>
                                    <td>{{ item.dcm_fpqrs | formatBoolean }}</td>
                                    <td>{{ item.dcm_inbox_uid }}</td>
                                    <td>{{ item.dcm_inbox_date | formatDate }}</td>
                                    <!--
                                    <td>{{ item.dcm_tres }}</td>
                                    <td>{{ item.restante }}</td>
                                  -->
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      <br>
                      <div v-if="showProgress" style="text-align:center">
                        <v-progress-circular
                          :size="50"
                          :width="5"
                          color="blue"
                          indeterminate
                        ></v-progress-circular>
                      </div>
                  </div>
                  <br>
                  <div class="row justify-content-around" align="center">
                    <button type="button" class="btn btn-primary" v-on:click="printReport('report')">Imprimir</button>
                    <button type="button" id="customXLSButton" class="btn btn-primary" v-on:click="exportReport">Exportar</button>
                  </div>
              </div>
          </div>
      </div>
      <!-- End PAge Content -->
  </div>
  <!-- End Container fluid  -->
</template>

<script>
//import { VProgressCircular } from 'vuetify/lib';
import moment from 'moment';
import { formatDate, formatBoolean } from './../../plugins/filters';

import print from 'print-js';
import XLSX from 'xlsx';

export default {
  components: {
    //VProgressCircular
  },
  data(){
    return{
      message: '',
      items: [],
      tpocors: [],
      dependencias: [],
      series: [],
      tpopqrs: [],
      indices: [],
      debug: null,
      fecha: null,
      rango: '',
      tcr_nmbre: '',
      dcm_fcharadini: '',
      dcm_fcharadfin: '',
      nuevos: false,
      showProgress: false
    }
  },
  filters: {
    formatDate, formatBoolean
  },
  computed:{
    companydata() {
      return this.$store.state.companydata
    }
  },
  created: function(){
    this.fecha = moment(new Date()).format('DD/MMM/YYYY hh:mm A');
    this.fetchTpocor();
    this.fetchDependencia();
    this.fetchSerie();
    this.fetchTpopqrs(err => {});
    this.fetchIndice(err => {});
    this.debug = this.$route.params;
    this.tcr_nmbre = this.$route.params.tcr_nmbre;
    this.dcm_fcharadini = this.$route.params.query.dcm_fcharadini;
    this.dcm_fcharadfin = this.$route.params.query.dcm_fcharadfin;
    this.nuevos = this.$route.params.query.nuevos;

    this.rango = "Desde: " + moment(this.dcm_fcharadini).format('DD/MMM/YYYY') + " --- Hasta: " + moment(this.dcm_fcharadfin).format('DD/MMM/YYYY');

    this.searchItems(this.$route.params.query);
  },
  mounted: function() {
    /*
    $('#myTable').DataTable({
    dom: 'Bfrtip',
    buttons: [
        'copy',
        'pdf',
        'excel'
    ]
    });
    */
  },
  methods: {
    fetchTpocor()
    {
      let uri = '/tpocors/correspondencia/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.tpocors = response.data;

        for (var i = 0; i < this.items.length; i++){
          for (var j = 0; j < this.tpocors.length; j++){
            if (this.items[i].tcr_id == this.tpocors[j]._id){
              this.items[i].tcr_nmbre = this.tpocors[j].tcr_nmbre;
              j = this.tpocors.length;
            }
          }
        }

      });
    },
    fetchDependencia()
    {
      let uri = '/deps/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.dependencias = response.data;

        for (var i = 0; i < this.items.length; i++){
          for (var j = 0; j < this.dependencias.length; j++){
            if (this.items[i].dep_id == this.dependencias[j]._id){
              this.items[i].dep_nmbre = this.dependencias[j].dep_nmbre;
              j = this.dependencias.length;
            }
          }
        }
        /*
        let m = response.data.index;
        this.files[m]['uploaded'] = '0';
        this.$set(this.files, m, this.files[m]);
        */
      });
    },
    fetchSerie()
    {
      let uri = '/series/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.series = response.data;

        for (var i = 0; i < this.items.length; i++){
          for (var j = 0; j < this.series.length; j++){
            if (this.items[i].ser_id == this.series[j]._id){
              this.items[i].ser_nmbre = this.series[j].ser_nmbre;
              j = this.series.length;
            }
          }
        }

      });
    },
    fetchTpopqrs(callback){
      let uri = '/tpopqrs/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.tpopqrs = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    fetchIndice(callback){
      let uri = '/indices/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.indices = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    searchItems(p){
      if (p != null){
        this.debug = p;
        this.showProgress = true;

        p.cmp_id = this.$store.state.company;
        //p.usu_id = String(this.$store.state.user);
        this.message = 'Buscando documentos';
        let uri = '/docs/report/docsradicados';
        //this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, p)
        .then((response) => {
          var list = response.data;
          this.items = [];

          for (var i = 0; i < list.length; i++){
            /*
            for (var j = 0; j < this.tpocors.length; j++){
              if (list[i].tcr_id == this.tpocors[j]._id){
                list[i].tcr_nmbre = this.tpocors[j].tcr_nmbre;
                j = this.tpocors.length;
              }
            }
            */

            for (var j = 0; j < this.dependencias.length; j++){
              if (list[i].dep_id == this.dependencias[j]._id){
                list[i].dep_nmbre = this.dependencias[j].dep_nmbre;
                j = this.dependencias.length;
              }
            }

            for (var j = 0; j < this.series.length; j++){
              if (list[i].ser_id == this.series[j]._id){
                list[i].ser_nmbre = this.series[j].ser_nmbre;
                if (list[i].dcm_tres == undefined){
                  list[i].dcm_tres = this.series[j].ser_tres;
                }
                j = this.series.length;
              }
            }

            for (var j = 0; j < this.tpopqrs.length; j++){
              if (list[i].tpq_id == this.tpopqrs[j]._id){
                list[i].tpq_nmbre = this.tpopqrs[j].tpq_nmbre;
                j = this.tpopqrs.length;
              }

            }          
            if (!list[i].tpq_nmbre){
              list[i].tpq_nmbre = 'Otra solicitud';           
            }

            if (list[i].indices.length > 0){
              for (var j = 0; j < list[i].indices.length; j++){
                for (var k = 0; k < this.indices.length; k++){
                  if (list[i].indices[j].ind_id == this.indices[k]._id){
                    list[i].indices[j].ind_nmbre = this.indices[k].ind_nmbre;
                    k = this.indices.length;
                  }
                }
              }
            }

            /*
            if (p.mora){
              if (list[i].restante < 0){
                this.items.push(list[i]);
              }
            } else {
              this.items.push(list[i]);
            }
            */

            if (list[i].dcmnt_docs.length > 0){
              for (var j = 0; j < this.tpocors.length; j++){
                if (list[i].dcmnt_docs[0].tcr_id == this.tpocors[j]._id){
                  list[i].respuesta = this.tpocors[j].tcr_cdgo + '-' + list[i].dcmnt_docs[0].dcm_ano + '-' + list[i].dcmnt_docs[0].dcm_cdgo;
                  j = this.tpocors.length;
                }
              }
            }

            if (list[i].usuario_docs.length > 0){
              list[i].ParaUsuarioNombre = list[i].usuario_docs[0].usu_nmbre + ' ' + list[i].usuario_docs[0].usu_aplldo;
            }

            if (list[i].seguimientos.length > 0){
              list[i].FechaSeguimiento = list[i].seguimientos[0].Fecha;
            }

            if (list[i].estado.length > 0){
              list[i].EstadoNombre = list[i].estado[0].Nombre;
            }

            if (!list[i].dcm_expediente || list[i].dcm_expediente == list[i]._id){
              list[i].nuevo = 'Si';
            } else {
              list[i].nuevo = 'No';
            }

            if (list[i]?.formatoPqrs?.length > 0){
              list[i].dcm_fpqrs = true;
            }
            
            this.items.push(list[i]);

          }
          this.showProgress = false;

        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar los documentos' + err;
        });
      }
    },
    printReport(object){
      var report = window.document.getElementById('report');
      var p = window.open('', 'Imprimir');
      p.document.write(
                "<!DOCTYPE html>"+
                "<html>"+
                "<head>"+
                "<link rel='stylesheet' href='../../bootstrap/css/bootstrap.min.css'></link>"+
                "<link rel='stylesheet' href='../../css/report.css'></link>"+
                "</head>"+
                "<body>"+
                    report.innerHTML+
                "</body>"+
                "</html>");
      p.document.close();

      setTimeout(function(){ //giving it 200 milliseconds time to load
              p.focus();
              p.print();
              p.close();
      }, 2000);
    },
    exportReport(){
      var tableId = 'reporteDocsRadicados';

      var report = [];
      for (var i = 0; i < this.items.length; i++){
        let item = {};
        item.dcm_ano = this.items[i].dcm_ano;
        item.dcm_cdgo = this.items[i].dcm_cdgo;
        item.dcm_fcharad = moment(this.items[i].dcm_fcharad).format('DD/MMM/YYYY hh:mm A');
        item.tpq_nmbre = this.items[i].tpq_nmbre;
        item.dcm_entidad = this.items[i].dcm_entidad;
        item.dcm_asunto = this.items[i].dcm_asunto;
        item.dcm_ciudad = this.items[i].dcm_ciudad;
        item.dep_nmbre = this.items[i].dep_nmbre;
        item.ser_nmbre = this.items[i].ser_nmbre;
        item.indices = '';
        if (this.items[i].indices.length > 0){
          for (var j = 0; j < this.items[i].indices.length; j++){
            item.indices += '{ ' + this.items[i].indices[j].ind_nmbre + ': ' + this.items[i].indices[j].ixd_valor + ' }';
          }
        }
        item.dcm_rspsta = this.items[i].dcm_rspsta ? 'Si' : 'No';
        if (this.items[i].FechaSeguimiento){
          item.FechaSeguimiento = moment(this.items[i].FechaSeguimiento).format('DD/MMM/YYYY hh:mm A');
        } else {
          item.FechaSeguimiento = '';
        }
        item.ParaUsuarioNombre = this.items[i].ParaUsuarioNombre;
        item.EstadoNombre = this.items[i].EstadoNombre;
        item.respuesta = this.items[i].respuesta;
        item.nuevo = this.items[i].nuevo;
        item.dcm_fpqrs = this.items[i].dcm_fpqrs ? 'Si' : 'No';
        item.dcm_inbox_uid = this.items[i].dcm_inbox_uid;
        if (this.items[i].dcm_inbox_date){
          item.dcm_inbox_date = moment(this.items[i].dcm_inbox_date).format('DD/MMM/YYYY hh:mm:ss.SSS A');
        }

        report.push(item);
      }

      var nuevos = this.nuevos ? "Sólo expedientes nuevos" : "";

      /* generate a new workbook with the first rows */
      var ws = XLSX.utils.aoa_to_sheet([
        [this.companydata.cmp_nmbre],
        [this.fecha],
        [],
      	["Reporte de Correspondencia radicada"],
      	["Clase de documentos: " + this.tcr_nmbre],
        [this.rango],
        [nuevos],
        [],
        ["Año", "Consecutivo", "Fecha Radicación", "Tipo solicitud", "Entidad", "Asunto", "Ciudad", "Dependencia", "Serie", "Indices", "Pendiente respuesta", "Fecha Último Seguimiento", "Para Usuario U. Seg.", "Estado U. Seg.", "Respuesta", "Nuevo expediente", "Datos de Pqrs", "Id Email", "Fecha Email"]
      ]);

      /* add row objects to sheet starting from cell A5 */
      var itemsWS = XLSX.utils.sheet_add_json(ws, report, { header: [], skipHeader: true, origin: "A10" });

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, itemsWS, tableId); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, tableId + '.xlsx'); // name of the file is 'book.xlsx'

    }

  } // END METHODS
}
</script>

<style>
  @import '../../../public/css/report.css';
</style>
